import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Program } from '../../../../../types/types';
import EditableCell from '../../../../../common/EditableCell';

interface ProgramInformationsProps {
  program: Program;
  onSaveProgramName: (id: string, newName: string) => void; // Add a prop for saving the program name
}

const ProgramInformations: React.FC<ProgramInformationsProps> = ({ program, onSaveProgramName }) => {
  const { t } = useTranslation('global');
  const [programName, setProgramName] = useState(program.programName);

  useEffect(() => {
    setProgramName(program.programName);
  }, [program.programName]);

  const handleSaveProgramName = (newName: string) => {
    setProgramName(newName);
    onSaveProgramName(program.id, newName); // Call the save function passed from the parent
  };


  return (
    <div>
        {t('programs.program_name')}: &nbsp;
        <EditableCell value={programName} onSave={handleSaveProgramName} />
        <img
          src="/images/navigation/edit.svg"
          alt="Edit"
          style={{ marginLeft: "10px", width: "16px", height: "16px" }}
        />
      <p>{t('programs.created_date')}: &nbsp;{program.createDate}</p>
    </div>
  );
};

export default ProgramInformations;
