import React, { Component } from 'react';
import { Exercise } from '../../../../../types/types';
import { withTranslation } from 'react-i18next';
import ExercisesService from '../../../../../services/exercises';
import Options from './Options';

interface Props {
    training_id: number;
    t: any;
}

interface State {
    exercises: Exercise[];
}

class GetExercise extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            exercises: []
        };
    }

    componentDidMount() {
        this.loadExercises();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.training_id !== this.props.training_id) {
            this.loadExercises();
        }
    }

    loadExercises = () => {
        const { training_id } = this.props;
        ExercisesService.getExercises(training_id)
            .then(response => this.setState({ exercises: response.data }))
            .catch(error => console.error('Error loading exercises:', error));
    };

    // handleDeleteExercise = (exerciseId: string) => {
    //     this.setState(prevState => ({
    //         exercises: prevState.exercises.filter(exercise => exercise.exerciseId !== exerciseId),
    //     }));
    // };

    render() {
        const { exercises } = this.state;
        const { t } = this.props;

        return (
            <div>
                <table className="fixed-table">
                    <thead>
                        <tr>
                           {/* <th className="column-small">t("table.order_number")</th>*/}
                            <th className="column-large">{t("table.exercise")}</th>
                            <th className="column-small">{/*t("table.quantity")*/}</th>
                            <th className="column-small">{/*t("table.type")*/}</th>
                            <th className="column-small">{/*t("table.volume")*/}</th>
                            <th className="column-small">{/*t("table.unit")*/}</th>
                            <th className='column-medium'>{/*t("table.notes")*/}</th>
                            <th className="column-small"> {/*t("table.options")*/}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {exercises.map((row) => (
                            <tr key={row.exerciseId}>
                                {/* <td>{row.orderNumber}</td> */}
                                <td className="column-large">{row.dictExerciseName}</td>
                                <td className="column-small">{row.quantity}</td>
                                <td className="column-small">{row.dictQuantityTypeName}</td>
                                <td className="column-small">{row.volume}</td>
                                <td className="column-small">{row.dictUnitName}</td>
                                <td className='column-medium'>{row.notes}</td>
                                <td className="column-small">
                                    <Options
                                        exerciseId={row.exerciseId}
                                        trainingId={row.trainingId}
                                        onExerciseUpdate={this.loadExercises}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation("global")(GetExercise);
